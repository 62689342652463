import { UseDatum } from 'react-usedatum';
import fire from '../fire';
import firebase from 'firebase/app';
import KeyMap from './KeyMap';

const datumCache: KeyMap<any> = {};
const closeDatumCache: KeyMap<() => void> = {};
export function useFirebaseDatum<T = any>(path: string, onDataRx?: (data: any) => T | undefined) {
  const datum = UseDatum<T | undefined>(undefined);
  let cachedDatum = datumCache[path];
  if (!cachedDatum) {
    cachedDatum = datum;
    datumCache[path] = datum;

    const rxResults = (snapshot: firebase.database.DataSnapshot) => {
      // console.log(`rx data ${path}`);
      const val = snapshot.val();
      if (val) {
        const cooked = onDataRx ? onDataRx(val) : val;
        datumCache[path]?.[1](cooked);
      }
    };

    const dataRef = fire.database().ref(path);
    closeDatumCache[path] = () => {
      dataRef.off('value', rxResults);
    };
    dataRef.on('value', rxResults);
  }
  return cachedDatum as typeof datum;
}

export function watchFirebaseDatum<T = any>(path: string, onDataRx: (data: T) => void) {
  const closeAction = closeDatumCache[path];
  if (!closeAction) {
    const rxResults = (snapshot: firebase.database.DataSnapshot) => {
      const val = snapshot.val();
      // console.log(`rx data ${path}`);
      if (val) {
        onDataRx(val);
      }
    };

    const dataRef = fire.database().ref(path);
    closeDatumCache[path] = () => {
      dataRef.off('value', rxResults);
    };
    dataRef.on('value', rxResults);
  }
}
export function watchFilteredFirebaseDatum<T = any>(
  path: string,
  key: string,
  value: string,
  onDataRx: (data: T) => void
) {
  const closeAction = closeDatumCache[path];
  if (!closeAction) {
    const rxResults = (snapshot: firebase.database.DataSnapshot) => {
      // console.log(`rx data ${path}`);
      const val = snapshot.val();
      onDataRx(val);
    };

    const dataRef = fire.database().ref(path);
    closeDatumCache[path] = () => {
      dataRef.off('value', rxResults);
    };
    dataRef.orderByChild(key).equalTo(value).on('child_added', rxResults);
  }
}

export function closeFirebaseDatum(path: string, match?: boolean) {
  // console.log(`Closing firebase datum for '${path}'`);
  if (match) {
    for (const key of Object.keys(closeDatumCache)) {
      if (key.includes(path)) {
        closeFirebaseDatum(key);
      }
    }
    return;
  }

  if (closeDatumCache[path]) {
    closeDatumCache[path]();
    delete closeDatumCache[path];
  }
  if (datumCache[path]) {
    datumCache[path][1](undefined);
    delete datumCache[path];
  }
}
