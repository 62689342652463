/* eslint-disable import/no-unassigned-import */
/**
 * fire-prod.js - Production firebase configuration
 */
import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/auth';
import 'firebase/storage';
const config = {
  /* COPY THE ACTUAL CONFIG FROM FIREBASE CONSOLE */
  apiKey: 'AIzaSyBxl61gy473Yq7KDT_838HYPnRsfZz_Y5M',
  authDomain: 'crewtimer-results.firebaseapp.com',
  databaseURL: 'https://crewtimer-results.firebaseio.com',
  projectId: 'crewtimer-results',
  storageBucket: 'crewtimer-results.appspot.com',
  messagingSenderId: '990343924949',
};
const fire = firebase.initializeApp(config);
export const auth = fire.auth();
export const storage = fire.storage();
export const database = fire.database();
export default fire;
