// src/CharacterTable.tsx
import React, { useEffect, useState } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import EventFilter, { useEventFilter } from '../shared/EventFilter';
import makeStyles from '@mui/styles/makeStyles';
import { useEventResults, useLogoURL, useRegattaInfo } from '../shared/UseResults';
import logo from '../logo.svg';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ChangeTracker, { useKioskEvent } from '../shared/ChangeTracker';

const useStyles = makeStyles((/* theme*/) => ({
  body: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    height: '100vh',
    padding: '16px',
    margin: 0,
    // backgroundImage: "url('https://northpb.com/public/sdcc/i-MhXn3dp-XL.jpg')",
    // backgroundSize: 'cover',
    // backgroundRepeat: 'no-repeat',
  },
  container: {
    borderRadius: '25px',
    paddingBottom: '8px',
  },
  title: {
    fontSize: 26,
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
  subtitle: {
    // fontFamily: "'Raleway', sans-serif",
    fontSize: 18,
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
  redbar: {
    height: '25px',
    color: 'white',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    // fontFamily: "'Lovelo', sans-serif",
    fontSize: '14px',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    lineHeight: '25px',
    marginBottom: '16px',
    marginTop: '10px',
  },
}));

const EventLogo: React.FC = () => {
  const [logoURL] = useLogoURL();
  return (
    <Box
      component="img"
      src={logoURL ? logoURL : logo}
      sx={{ width: 'auto', height: 68, marginLeft: '8px', cursor: 'none' }}
    />
  );
};

const CharacterTable: React.FC = () => {
  const classes = useStyles();
  const [regattaData] = useEventResults();
  const [eventFilter, setEventFilter] = useEventFilter();
  const [regattaInfo] = useRegattaInfo();
  const [showEventFilter, setShowEventFilter] = useState(false);
  const location = useLocation();
  const [kioskEvent] = useKioskEvent();
  const { t } = useTranslation();

  const searchParams = new URLSearchParams(location.search);

  const getSearchParam = (key: string, defaultValue: string) => {
    const val = searchParams.get(key);
    return val ? val : defaultValue;
  };

  const getColorParam = (key: string, defaultValue: string) => {
    const val = searchParams.get(key);
    if (!val) {
      return defaultValue;
    }
    if (val.match(/^[0-9a-fA-F]{6}([0-9a-fA-F]{2})?$/)) {
      return `#${val}`; // hex rgb
    }
    return val;
  };

  const selectedEvent = regattaData.find((event) => event.EventNum === eventFilter) || regattaData[0];
  const waypoint = getSearchParam('waypoint', 'finish').toLowerCase();
  const width = searchParams.get('width') || '600px';
  const showScheduleOrder = waypoint === 'start';
  const align = getSearchParam('align', 'bottom').toLowerCase();

  const pageBg = searchParams.get('page-bg') || 'none';
  const barBg = getColorParam('bar-bg', 'rgba(167,28,32,100)');
  const titleTextColor = getColorParam('title-color', 'white');
  const bg = getColorParam(
    'bg',
    pageBg === 'none' || pageBg === 'transparent' ? 'rgba(27,49,93,0.80)' : 'rgba(27,49,93,1.0)'
  );

  const kioskEnabled = getSearchParam('kiosk', 'false') === 'true';
  const showTime = getSearchParam('showTime', 'false') === 'true';
  const showTitle = getSearchParam('showTitle', 'true') === 'true';
  const showBody = getSearchParam('showBody', 'true') === 'true';
  const showLogo = getSearchParam('showLogo', 'true') === 'true';

  const titleText = getSearchParam('title', regattaInfo?.Title || '');
  const subtitleText = getSearchParam('subtitle', selectedEvent?.EventInfo || '');
  const columnTitles = regattaInfo?.Titles || {};
  const alignStyle = { justifyContent: 'center', alignItems: 'flex-end' };
  switch (align) {
    case 'top':
      alignStyle.alignItems = 'flex-start';
      break;
    case 'bottom':
      alignStyle.alignItems = 'flex-end';
      break;
    case 'center':
      alignStyle.alignItems = 'center';
    default:
      break;
  }

  console.log({ align, alignStyle });

  const getTitle = (name: string) => {
    if (name === 'Stroke/Cox') {
      return columnTitles.Stroke || `${t('Stroke')}/${t('Cox')}`;
    }
    return columnTitles[name] || t(name);
  };

  useEffect(() => {
    const urlEventNum = searchParams.get('eventNum');
    if (urlEventNum) {
      setEventFilter(urlEventNum);
    } else if (!eventFilter && regattaData.length) {
      setEventFilter(regattaData[0].EventNum);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (kioskEnabled && kioskEvent) {
      setEventFilter(kioskEvent);
    }
  }, [kioskEvent, kioskEnabled, setEventFilter]);

  let entries = selectedEvent?.entries || [];
  if (showScheduleOrder) {
    // Make a copy as sort does inplace and we don't want to disturb the backing store
    entries = [...entries].sort((a, b) => a.Index - b.Index);
  }

  const onNextEvent = () => {
    if (regattaData.length === 0) {
      return;
    }
    const index = regattaData.findIndex((event) => event.EventNum === eventFilter);
    const nextIndex = Math.min(regattaData.length - 1, index + 1);
    setEventFilter(regattaData[nextIndex].EventNum);
  };
  const onPrevEvent = () => {
    if (regattaData.length === 0) {
      return;
    }
    const index = regattaData.findIndex((event) => event.EventNum === eventFilter);
    const nextIndex = Math.max(0, index - 1);
    setEventFilter(regattaData[nextIndex].EventNum);
  };

  return (
    <>
      {showEventFilter && (
        <Box sx={{ position: 'absolute', width: '100%' }}>
          <EventFilter />
        </Box>
      )}
      <Box className={classes.body} sx={{ background: pageBg, ...alignStyle }} style={{ ...alignStyle }}>
        <ChangeTracker />
        <Stack direction="column" className={classes.container} sx={{ width: `${width}`, background: bg }}>
          {showTitle ? (
            <Stack direction="row" sx={{ paddingTop: '16px' }}>
              {showLogo && <EventLogo />}
              <Stack direction="column" sx={{ paddingLeft: '26px' }}>
                <Box
                  className={classes.title}
                  sx={{ color: titleTextColor }}
                  onClick={() => setShowEventFilter(!showEventFilter)}
                >
                  {titleText}
                </Box>
                <Box className={classes.subtitle} sx={{ color: titleTextColor }}>
                  {subtitleText}
                </Box>
                <Box className={classes.subtitle} sx={{ color: titleTextColor }}>
                  {}
                </Box>
              </Stack>
            </Stack>
          ) : (
            <Box sx={{ height: '16px' }} />
          )}
          <Box className={classes.redbar} sx={{ background: barBg }}>
            <Typography
              variant="body1"
              component="div"
              sx={{ paddingLeft: '16px', fontSize: '14px' }}
              onClick={onPrevEvent}
            >
              {`${showScheduleOrder && showBody ? 'START LIST - ' : ''}${selectedEvent?.Event}`}
            </Typography>
            <Typography
              variant="body1"
              component="div"
              sx={{
                paddingRight: '16px',
                fontSize: '14px',
                whiteSpace: 'pre',
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
                minWidth: '50px',
                height: '25px', // required so blank content is clickable
              }}
              onClick={onNextEvent}
            >
              {showScheduleOrder ? '' : getTitle(showTime ? 'Time' : 'Split')}
            </Typography>
          </Box>
          {showBody &&
            entries.map((entry, rowNum) => (
              <Box
                key={entry.Bow}
                sx={{
                  height: '25px',
                  background: 'rgba(255,255,255,0.50)',
                  marginBottom: '5px',
                  marginLeft: '8px',
                  marginRight: '8px',
                  fontSize: '14px',
                  lineHeight: '25px',
                  cursor: 'none',
                }}
              >
                <Stack direction="row" alignItems="center" spacing={2}>
                  {/* First element: Fixed width, centered */}
                  <Box
                    sx={{
                      width: 30,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      marginleft: '8px',
                      textTransform: 'uppercase',
                      fontWeight: 'bold',
                    }}
                  >
                    {entry.Bow}
                  </Box>

                  {/* Second element: Left-justified, center vertical alignment */}
                  <Box
                    sx={{
                      flexGrow: 1,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'flex-start',
                      textTransform: 'uppercase',
                      fontWeight: 'bold',
                      whiteSpace: 'nowrap' /* Prevents the text from wrapping to a new line */,
                      overflow: 'hidden' /* Ensures the overflow is hidden */,
                      // textOverflow: 'ellipsis' /* Adds an ellipsis to signify clipped text */,
                    }}
                  >
                    {entry.Crew}
                  </Box>

                  {/* Third element: Right-justified, fixed width */}
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      alignItems: 'center',
                      fontFamily: 'monospace',
                      paddingRight: '8px',
                      textTransform: 'uppercase',
                      fontWeight: 'bold',
                    }}
                  >
                    {showScheduleOrder
                      ? ''
                      : showTime || rowNum === 0
                      ? entry.AdjTime
                      : entry.Delta
                      ? `+${entry.Delta}`
                      : ''}
                  </Box>
                </Stack>
              </Box>
            ))}
        </Stack>
      </Box>
    </>
  );
};

export default CharacterTable;
